import { Eq } from "fp-ts/lib/Eq";
import * as O from "fp-ts/lib/Option";
import { useCallback, useMemo } from "react";
import * as RsSelect from "react-select";
import "@/assets/styles/basic/Select/select.css";
import { pipe } from "fp-ts/lib/function";

export type SelectProps<T> = {
  options: T[];
  selected: O.Option<T>;
  valueEq: Eq<T>;
  placeholder?: string;
  renderLabel: (value: T) => string;
  onChange: (value: T) => void;
  menuPlacement?: "top" | "bottom";
  container?: HTMLDivElement | null;
  selectContainerClassName?: string
};

export function Select<T>(props: SelectProps<T>) {
  const toItem = useCallback(
    (v: T) => ({
      label: props.renderLabel(v),
      value: v,
    }),
    [props],
  );
  const selectOptions = useMemo(() => {
    return props.options.map(toItem);
  }, [props.options, toItem]);

  const selectedValue = pipe(
    props.selected,
    O.fold(() => null, toItem),
  );

  return (
    <div className={`select-container ${props.selectContainerClassName ? props.selectContainerClassName : ''}`}>
      <RsSelect.default
        options={selectOptions}
        onChange={(v) => {
          if (!v) {
            return;
          }
          props.onChange(v.value);
        }}
        value={selectedValue}
        className="react-select-container"
        classNamePrefix="react-select"
        menuPlacement={props.menuPlacement || "bottom"}
        menuShouldScrollIntoView={true}
        menuPortalTarget={props.container}
        menuPosition="fixed"
      />
    </div>
  );
}
