import "@/assets/styles/Tooltip/tooltip.css";
import { User } from "@/data/client";
import {
  ClickEvent,
  Menu,
  MenuButton,
  MenuDivider,
  MenuHeader,
  MenuItem,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { constant, flow, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { Button } from "../Button";
import { Icon, IconType } from "../Icon";
import { Row } from "../Row";
import { Fragment, useContext, useRef } from "react";
import { RouterContext } from "@/utils/router-context";
import { ViewResolver } from "@/utils/viewResolver";
import { getProfileId, getUserName } from "@/utils/user";
import { Tooltip, TooltipRefProps } from "react-tooltip";

type Props = { user: User; menuItems: ProfileMenuDef[] };

export type ProfileMenuDef = {
  icon?: IconType;
  label: string;
  onClick: O.Option<(e: ClickEvent) => void>;
  header?: string;
  hasDivider?: boolean;
  showCopyIcon?: boolean;
};

export function ProfileMenu(props: Props): JSX.Element {
  const { router } = useContext(RouterContext);
  const tooltipRef1 = useRef<TooltipRefProps>(null);

  const copyIcon = (
    <Button
      onClick={O.some(() => {
        navigator.clipboard.writeText("Some");
        tooltipRef1.current?.open({
          anchorSelect: "#my-element",
          content: "Copied!",
        });
        setTimeout(() => tooltipRef1.current?.close(), 500);
      })}
      type="flat"
    >
      <div id="my-element">
        <Icon type="copy"></Icon>
      </div>
    </Button>
  );

  return (
    <Row alignVertical="center">
      <Row grow={1} alignHorizontal="right" gap="md" alignVertical="center">
        <Button
          type="tertiary"
          className="ac-menu-icon menu-notification-btn"
          onClick={O.some(() =>
            flow(constant("/notifications"), router.navigate),
          )}
        >
          <i className="fa-regular fa-bell menu-notification-icon"></i>
        </Button>
      </Row>

    <Menu
        arrow={true}
        menuButton={
          <MenuButton className="button button-flat text-sm">
            <Row gap="xs" alignVertical="baseline" padding="xs">
              <div className="applicant-id">
                {pipe(props.user, getUserName, getProfileId)}
              </div>
              <div>
                {ViewResolver({
                  viewModes: [["Default"], ["Mobile-Portrait"]],
                  resolvedContent: [getUserName(props.user), ""],
                })}
              </div>
              <Icon type="chevron-up"></Icon>
            </Row>
          </MenuButton>
        }
        transition
        submenuCloseDelay={40000}
      >
        {props.menuItems.map(
          ({ label, onClick, hasDivider, header, showCopyIcon }, i) => (
            <Fragment key={i}>
              {header ? <MenuHeader>{header}</MenuHeader> : <></>}
              <MenuItem
                className={showCopyIcon ? 'copy-item' : ''}
                onClick={(e) =>
                  pipe(
                    onClick,
                    O.fold(
                      () => {},
                      (v) => v(e),
                    ),
                  )
                }
              >
                {label} {showCopyIcon ? <>{copyIcon} <Tooltip ref={tooltipRef1} delayHide={500} /></> : <></>}
              </MenuItem>

              {hasDivider ? <MenuDivider /> : <></>}
            </Fragment>
          ),
        )}
      </Menu>
    </Row>
  );
}
