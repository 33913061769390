export function formatConfirmSsn(ssn: string): string {
    const prefix = "•••-••-";
    if (!/^\d+$/.test(ssn)) {
      return `${prefix}`;
    }
  
    return `${prefix}${ssn.slice(-4)}`;
}

export function formatPhoneNumber(phoneNumber: string): string {
    // check that the input only contains digits
    // otherwise return the input as is
    if (!/^\d+$/.test(phoneNumber) || phoneNumber.length < 3) {
      return phoneNumber;
    }
  
    const areaCode = phoneNumber.slice(0, 3);
    const prefix = phoneNumber.slice(3, 6);
    const lineNumber = phoneNumber.slice(6, 10);
    const leftOver = phoneNumber.slice(10);
  
    if (phoneNumber.length <= 3) {
      return `(${areaCode}`;
    }
    if (phoneNumber.length <= 6) {
      return `(${areaCode}) ${prefix}`;
    }
  
    if (phoneNumber.length <= 10) {
      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }
  
    return `(${areaCode}) ${prefix}-${lineNumber} ${leftOver}`;
  }