import apartment from "@/assets/images/apartment.jpg";
import dots from "@/assets/images/dots.png";
import greenStripes from "@/assets/images/green-stripes.jpg";
import homeChecking from "@/assets/images/home-checking.jpg";
import appCatcherLogo from "@/assets/images/home-page-app-catcher-logo.png";
import circleBlue from "@/assets/images/home-page-section-02-main-01-mobile.png";
import "@/assets/styles/InactiveBorrower/css/styles.css";
import { ChildProps } from "@/utils/reducerWithEffect";
import { Button, Col, Icon, Row } from "../basic";
import { Action } from "./action";
import { Model } from "./model";
import { formatPhoneNumber } from "@/utils/formatters";
import * as O from "fp-ts/Option";
import { Tooltip, TooltipRefProps } from "react-tooltip";
import { useRef } from "react";
import { ViewResolver } from "@/utils/viewResolver";

export type Props = Omit<ChildProps<Model, Action>, "dispatch">;

export function View(props: Props): JSX.Element {
  const { forBorrower } = props.model;
  const ctaImage = forBorrower ? homeChecking : apartment;
  const tooltipRef1 = useRef<TooltipRefProps>(null);

  const status = props.model.status;
  return (
    <Col
      alignHorizontal="center"
      padding={ViewResolver({
        viewModes: [
          ["Default"],
          [
            "Tablet-Portrait",
            "Tablet-Landscape",
            "Mobile-Portrait",
            "Mobile-Landscape",
          ],
        ],
        resolvedContent: ["fallback", "md"],
      })}
      className="inactive-wrapper"
    >
      <img src={appCatcherLogo} style={{ width: "10em" }} />
      <div
        className={ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Tablet-Portrait",
              "Tablet-Landscape",
              "Mobile-Portrait",
              "Mobile-Landscape",
            ],
          ],
          resolvedContent: ["grid-3 gap-xs", "grid"],
        })}
        style={{ width: "100%", height: "calc(100vh - 68px)" }}
      >
        {ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Tablet-Portrait",
              "Tablet-Landscape",
              "Mobile-Portrait",
              "Mobile-Landscape",
            ],
          ],
          resolvedContent: [
            <Col alignVertical="center">
              <div style={{ aspectRatio: "3/2" }}>
                <img
                  className="mix-blend"
                  src={circleBlue}
                  style={{
                    filter: "grayscale(1)",
                    opacity: "0.3",
                    width: "15vw",
                  }}
                />
              </div>
            </Col>,
            <></>,
          ],
        })}
        <Col alignVertical="center" alignHorizontal="center">
          <img src={ctaImage} className="cta-image mix-blend" />
          <Col>
            <p className="text-bold">There seems to be an issue.</p>
            <p> Hi, welcome back to AppCatcher.</p>
            <p>
              <span className="text-bold">
                {status.companyName} is no longer available in AppCatcher.{" "}
              </span>{" "}
            </p>
            <p>
              We recommend you contact your{" "}
              {forBorrower ? "loan officer" : "support team"} for further
              information{" "}
            </p>
            {forBorrower ? (
              <></>
            ) : (
              <Col gap="xs">
                <Row gap="xs" alignVertical="baseline">
                  <Icon type="eye"></Icon>
                  <div>{status.email}</div>
                  <Button
                    onClick={O.some(() => {
                      navigator.clipboard.writeText(status.email);
                      tooltipRef1.current?.open({
                        anchorSelect: "#my-element",
                        content: "Copied!",
                      });
                      setTimeout(() => tooltipRef1.current?.close(), 500);
                    })}
                    type="flat"
                  >
                    <div id="my-element">
                      <Icon type="copy"></Icon>
                    </div>
                  </Button>

                  <Tooltip ref={tooltipRef1} delayHide={500} />
                </Row>
                <Row gap="xs" alignVertical="baseline">
                  <Icon type="phone"></Icon>
                  <div>{formatPhoneNumber(status.phone)}</div>
                </Row>
              </Col>
            )}
          </Col>
        </Col>
        {ViewResolver({
          viewModes: [
            ["Default"],
            [
              "Tablet-Portrait",
              "Tablet-Landscape",
              "Mobile-Portrait",
              "Mobile-Landscape",
            ],
          ],
          resolvedContent: [
            <div
              style={{
                height: "calc(100vh-7em)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img src={dots} style={{ width: "5em" }} />
              <img
                src={greenStripes}
                style={{ height: "15em" }}
                className="mix-blend"
              />
            </div>,
            <></>,
          ],
        })}
      </div>
    </Col>
  );
}
